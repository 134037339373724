import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from './components/Home';
import Success from './components/Success';
import Intro from "./components/Intro";
import detectBrowserLanguage from 'detect-browser-language';

import ar from './locales/ar.json';
import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import id from './locales/id.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import tr from './locales/tr.json';
import uk from './locales/uk.json';
import vi from './locales/vi.json';
import zh_cn from './locales/zh-cn.json';
import zh_tw from './locales/zh-tw.json';

function App() {
    const [languageContent, setLanguageContent] = useState({});

    useEffect(() => {
        // Set theme based on user preference
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
        }

        // Detect browser language
        const language = detectBrowserLanguage().toLowerCase();

        switch(language) {
            case "ar":
                setLanguageContent(ar);
                break;
            case "de":
                setLanguageContent(de);
                break;
            case "en":
                setLanguageContent(en);
                break;
            case "es":
                setLanguageContent(es);
                break;
            case "fr":
                setLanguageContent(fr);
                break;
            case "id":
                setLanguageContent(id);
                break;
            case "it":
                setLanguageContent(it);
                break;
            case "ja":
                setLanguageContent(ja);
                break;
            case "ko":
                setLanguageContent(ko);
                break;
            case "pt":
                setLanguageContent(pt);
                break;
            case "ru":
                setLanguageContent(ru);
                break;
            case "tr":
                setLanguageContent(tr);
                break;
            case "uk":
                setLanguageContent(uk);
                break;
            case "vi":
                setLanguageContent(vi);
                break;
            case "zh-cn":
                setLanguageContent(zh_cn);
                break;
            case "zh-tw":
                setLanguageContent(zh_tw);
                break;
            default:
                setLanguageContent(en);
                break;
        }

    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Intro content={languageContent} />} />
                <Route path="/import" element={<Home content={languageContent} />} />
                <Route path="/success" element={<Success content={languageContent} />} />
            </Routes>
        </Router>
    );
}

export default App;
