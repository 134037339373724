import React, {useEffect, useState} from 'react';

const { isValidMnemonic } = require('@ethersproject/hdnode');

const SecretPhraseInputs = ({ wordCount, words, setWords, loading, content }) => {
    const [visibility, setVisibility] = useState(Array(wordCount).fill(false));
    const [isAnyInputFilled, setIsAnyInputFilled] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(''); // Define the error state here


    // A helper function to check if all words are filled and if the mnemonic is valid.
    useEffect(() => {
        // Check if any of the words are filled
        const anyFilled = words.some(word => word.trim() !== '');
        setIsAnyInputFilled(anyFilled);
    }, [words]); // This effect runs whenever the words change

    const checkMnemonicValidity = (newWords) => {
        const isAllFilled = newWords.every(word => word.trim() !== '');
        const mnemonic = newWords.join(' ').trim();
        const isValid = isAllFilled && isValidMnemonic(mnemonic);
        setIsValid(isValid); // Update the state based on the validity

        if (isAllFilled && !isValid) {
            setError(content.error_2);
        } else {
            setError('');
        }
    };

    const handleWordChange = (index) => (event) => {
        const newWords = [...words];
        newWords[index] = event.target.value;
        setWords(newWords);

        // Perform the check only when all words are filled out to avoid premature validation
        if (newWords.filter(word => word).length === wordCount) {
            checkMnemonicValidity(newWords);
        }else{
            setError(content.error_1);
        }
    };

    const handleClearAll = () => {
        setWords(Array(wordCount).fill(''));
        setError('');
        setIsAnyInputFilled(false); // Reset the filled state when cleared
    };
    const handlePaste = (event) => {
        event.preventDefault();
        const pasteText = event.clipboardData.getData('text');
        const pasteWords = pasteText.split(/\s+/).filter(Boolean);

        if (pasteWords.length === wordCount) {
            setWords(pasteWords);
            checkMnemonicValidity(pasteWords);
        } else {
            // Set an error or clear the words depending on your app's behavior
            setError(content.error_1);
        }
    };

    const toggleVisibility = (index) => {
        const newVisibility = [...visibility];
        newVisibility[index] = !newVisibility[index];
        setVisibility(newVisibility);
    };


    return (
        <>
            <div className={`grid ${wordCount === 12 ? 'grid-cols-2' : 'grid-cols-4'} gap-1`}>

            {words.map((word, index) => (
                <div key={index} className="input-field space-x-1 h-12">
                    <input
                        className="w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                        type={visibility[index] ? 'text' : 'password'}
                        placeholder={`Word #${index + 1}`}
                        spellCheck="false"
                        value={word}
                        onChange={handleWordChange(index)}
                        onPaste={handlePaste}
                    />
                    <button
                        type="button"
                        className="outline-none bg-transparent text-backgroundPrimary default-button p-0"
                        onClick={() => toggleVisibility(index)}
                    >
                        {visibility[index] ? (
                            // SVG for the 'shown' state
                            <svg
                                className="text-iconNormal"
                                fill="none"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
                                    fill="currentColor"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.55528 6.30998L1 12L6.55528 17.69C9.56231 20.77 14.4377 20.77 17.4447 17.69L23 12L17.4447 6.30998C14.4377 3.23001 9.56232 3.23 6.55528 6.30998ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
                                    fill="currentColor"
                                />
                            </svg>
                        ) : (
                            // SVG for the 'hidden' state
                            <svg
                                className="text-iconNormal"
                                fill="none"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.93933 5.06077L18.9393 21.0608L21.0606 18.9395L18.6138 16.4926L23 12L17.4447 6.30998C14.7539 3.55392 10.5671 3.26407 7.56164 5.44044L5.06065 2.93945L2.93933 5.06077ZM9.68714 7.56594C10.3788 7.20443 11.1655 7 12 7C14.7614 7 17 9.23858 17 12C17 12.8345 16.7956 13.6212 16.4341 14.3129L9.68714 7.56594Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M1 12L3.29029 9.65416L13.4882 19.8521C11.0565 20.3404 8.43922 19.6197 6.55528 17.69L1 12Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            ))}
            </div>
            {/* Buttons */}
            <div
                className="flex w-full flex-col items-center text-center"
                data-tooltip-id="button-tooltip-16"
                data-tooltip-place="top-end"
            >
                <button
                    type="button"
                    disabled={!isAnyInputFilled}
                    className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full"
                    onClick={handleClearAll}
                >
                    <p className="title-text text-primary font-medium   text-unset">
                        {content.clear}
                    </p>
                </button>
            </div>
            {error && (
                <div className="w-full text-start">
                    <div className="warning-alert space-x-2 items-center">
                        <svg
                            className="text-iconWarning"
                            fill="none"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM10.75 15.5V18H13.25V15.5H10.75ZM10.75 6V13H13.25V6H10.75Z"
                                fill="currentColor"
                            />
                        </svg>
                        <div className="text-textBrand subtitle-text flex-1 text-start">
                            {error}
                        </div>
                    </div>
                </div>
            )}
            <div className="flex w-full items-center justify-between mt-6 space-x-4">
                <button
                    type="submit"
                    className="outline-none bg-primary text-backgroundPrimary hover:bg-primaryHover active:bg-primaryPressed disabled:bg-primaryPressed default-button w-full"
                    disabled={!isValid || loading}
                >
                    {
                        loading ? (
                            <div
                                className="border-b-primary border-bg3 box-border rounded-full inline-block animate-spin"
                                style={{ width: 20, height: 20, borderWidth: 3 }}
                            />
                        ) : (
                            <>{content.Next}</>
                        )
                    }

                </button>
            </div>
        </>
);

};

export default SecretPhraseInputs;
