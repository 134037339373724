import './../App.css';
import light_logo from './../light_logo.svg'
import dark_Logo from './../dark_logo.svg'
import MnemonicValidator from "./MnemonicValidator";
import {useEffect, useState} from "react";


const Home = ( {content} ) => {
    const [loading , setLoading] = useState(true);
    const [theme, setTheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        // Function to update state based on system theme change
        const handleChange = (e) => {
            setTheme(e.matches ? 'dark' : 'light');
        };

        // Add listener for changes in system theme
        mediaQuery.addEventListener('change', handleChange);

        // Clean up the listener on component unmount
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        setTimeout(function () {
            setLoading(false);
        }, 3000);
    }, []);

    return(
        <div id="root" data-id="root">
            <div className="flex my-11 justify-center">
                {theme === 'dark' ? (
                    <img src={dark_Logo}/>
                ) : (
                    <img src={light_logo}/>

                )}

            </div>

            {loading ? (
                <div className="flex my-11 justify-center">
                    <div
                        className="border-b-primary border-bg3 box-border rounded-full inline-block animate-spin"
                        style={{ width: 50, height: 50, borderWidth: 3 }}
                    />
                </div>
            ) : (
                <MnemonicValidator content={content} />
            )}

        </div>

        );
}

export default Home;